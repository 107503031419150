<template>
  <v-container class="pa-0" fluid>

    <v-row class="pa-0 ma-0">

      <v-dialog scrollable v-model="dialog" fullscreen>
        <v-card>
          <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-1">
            <span>{{ editedItem.tmplName }} </span>
            <v-spacer />
            <v-btn @click="close" class="ma-0" text color="error">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-row class="mx-0">
              <v-col cols="12">
                <v-expansion-panels focusable>
                  <v-expansion-panel class="pa-0 ma-0">
                    <v-expansion-panel-header class="px-3 py-2">
                      <span>{{ $t('generic.lang_templateVariables') }}</span>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0 pt-2">
                      <v-row>
                        <v-col cols="12">
                          <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                            :placeholder="$t('datatables.lang_search')" v-model="search" />
                        </v-col>
                        <v-col cols="12">
                          <v-row class="pa-0 ma-0 custom-vars-cont">
                            <v-col :key="i" class="pt-0 pb-0" cols="12" md="6" sm="12" v-for="(elt , i) in filteredVars">
                              <v-text-field :id="'input_' + i" :value="elt"
                                :background-color="$vuetify.theme.dark? 'dark-bg' : 'grey lighten-3'" dense outlined
                                readonly>
                                <template v-slot:append>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn @click="copyText('input_' + i , elt)" @mouseout="out" icon v-bind="attrs"
                                        v-on="on">
                                        <v-icon>content_copy</v-icon>
                                      </v-btn>
                                    </template>
                                    {{ toolpitMsg }}
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-col class="pa-0 ma-0" cols="12">
                <v-form lazy-validation ref="form">
                  <v-row class="pa-0 ma-0">
                    <v-col class="pt-0 mt-0" cols="12">
                      <quill-editor id="editor" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                        :rules="[v => !!v]" @focus="showTouchKeyboard" output="html"
                        v-model="editedItem.text"></quill-editor>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right pt-1 pb-1">
            <v-spacer />
            <v-btn @click="close" class="bg-danger text-light mx-0">
              {{ this.$t('generic.lang_cancel') }}
            </v-btn>
            <v-btn :disabled="this.loading" :loading="this.loading" @click="saveTamplate"
              class="bg-primary text-light mx-1">
              {{ this.$t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-col class="mt-0 pt-0" cols="12">
        <v-row>
          <v-col class="pa-0 ma-0" cols="12">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left text-uppercase">{{ $t('generic.lang_template') }}</th>
                  <th class="text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('voucherbee.lang_orderEmailTMPL') }}</td>
                  <td class="text-right">
                    <v-btn fab x-small depressed @click="entryEdit('order')" color="primary">
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('voucherbee.lang_giftedEmailTMPL') }}</td>
                  <td class="text-right">
                    <v-btn fab x-small depressed @click="entryEdit('gifted')" color="primary">
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input" :layout="touchKeyboard.layout" :options="touchKeyboard.options"
        class="internalWidth" id="onScreenKeyboard" v-if="touchKeyboard.visible" />
    </div>
  </v-container>
</template>
  
  <script>
  import {ENDPOINTS} from "@/config";
  
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import {quillEditor} from 'vue-quill-editor';
  
  import mixin from "@/mixins/KeyboardMixIns";
  import {Events} from "@/plugins/events";
  
  export default {
    name: "EmailTemplatesComponent",
    components: {quillEditor},
    mixins: [mixin],
    data(){
      return {
        voucherPortal_giftedEmailTMPL: "",
        voucherPortal_orderEmailTMPL: "",
        editedItem: {
          target: null,
          tmplName: "",
          text: "",
        },
        default: {
          target: null,
          tmplName: "",
          text: "",
        },
        quillOptions: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              [{'align': []}],
              [{'font': []}],
            ],
          },
        },
        edit: false,
        loading: false,
        ENDPOINTS,
        search: "",
        dialog: false,
        toolpitMsg: this.$t('generic.lang_CopyToClipboard'),
      }
    },
  
    methods: {
      saveTamplate() {
        if (!this.$refs.form.validate()) return;
        if (this.editedItem.text === "") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_pleaseSetYourTemplateText'),
            color: "error"
          });
          return;
        }
        this.loading = true;
        this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.EMAIL.TEMPLATES.UPDATE, {
          voucherPortal_giftedEmailTMPL: this.editedItem.target === 'voucherPortal_giftedEmailTMPL' ? this.editedItem.text : this[this.editedItem.target],
          voucherPortal_orderEmailTMPL: this.editedItem.target === 'voucherPortal_orderEmailTMPL' ? this.editedItem.text : this[this.editedItem.target]
        }).then((res) => {
          if (res.data.status === 'SUCCESS') {
            // update dataTable
            this.getAllTemplates();
            this.close();
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        })
      },
      getAllTemplates() {
        this.loading = true;
        this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.EMAIL.TEMPLATES.GET)
            .then((res) => {
              if(res.status === 200){
                this.voucherPortal_giftedEmailTMPL = res.data.formfillData.textFields.voucherPortal_giftedEmailTMPL;
                this.voucherPortal_orderEmailTMPL = res.data.formfillData.textFields.voucherPortal_orderEmailTMPL;
              }
            }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        })
      },
      out() {
        this.toolpitMsg = this.$t('generic.lang_CopyToClipboard')
      },
      copyText(id, elt) {
        elt = elt.substring(elt.search('{{'), elt.search('}}') + 2);
        //console.log(id)
        var copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.toolpitMsg = this.$t('generic.lang_copied') + ": " + elt;
        this.editedItem.text += " " + elt;
      },
      entryEdit(entry) {
        if(entry === 'order'){
          this.editedItem = Object.assign({}, {
            tmplName: this.$t('voucherbee.lang_orderEmailTMPL'),
            text: this.voucherPortal_orderEmailTMPL,
            target: 'voucherPortal_orderEmailTMPL'
          });
        }else {
          this.editedItem = Object.assign({}, {
            tmplName:  this.$t('voucherbee.lang_giftedEmailTMPL'),
            text: this.voucherPortal_giftedEmailTMPL,
            target: 'voucherPortal_giftedEmailTMPL'
          });
        }
        this.edit = true;
        this.dialog = true;
      },
      close() {
        this.dialog = false;
        this.editedItem = Object.assign({}, this.default);
        this.$refs.form.reset();
      }
    },
    watch: {
      dialog(val) {
        val || this.close();
      }
    },
    computed: {
      variations() {
        return [
          this.$t('generic.lang_recipientFirstName') + "  - {{recipientFirstName}}",
          this.$t('generic.lang_recipientLastName') + "  - {{recipientLastName}}",
          this.$t('generic.lang_recipientNote') + "  - {{recipientNote}}",

          this.$t('generic.lang_invoicePositions') + "  - {{invoicePositions}}",// as a table containing cart items
          
          this.$t('customers.lang_cust_prename') + "  - {{Prename}}",
          this.$t('customers.lang_cust_name') + "  - {{Lastname}}",
          this.$t('generic.lang_phoneNumber') + "  - {{phoneNumber}}",
          
          this.$t('settings.lang_companyName') + "  - {{Company}}",
          this.$t('settings.lang_billingAdress') + "  - {{billingAdress}}",
          this.$t('settings.lang_billingZip') + "  - {{billingZip}}",
          this.$t('settings.lang_billingCity') + "  - {{billingCity}}",
          this.$t('settings.lang_billingCountry') + "  - {{billingCountry}}",
        ]
      },
      filteredVars(){
        return this.variations.filter((v) => v.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
      }
    },
    mounted() {
      this.getAllTemplates()
    }
  }
  </script>
  
  <style scoped>
  v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  
  .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none !important;
    border: none !important;
  }
  
  #editor {
    height: 300px !important;
    width: 100%;
  }
  .custom-vars-cont {
    max-height: 180px !important;
    overflow-y: auto;
  }
  </style>
  